import { IWixAPI } from '@wix/yoshi-flow-editor';
import { SEARCH_APP_APP_DEF_ID } from './constants';

export const trimStringSpaces = (str: string) => {
  return str.trimStart().replace(/\s+/g, ' ');
};

export const getTranslationOrUserText = ({
  label,
  translation,
  defaultLabelInBlock,
}: {
  label: string;
  translation: string;
  defaultLabelInBlock: string;
}) => {
  if (!label || defaultLabelInBlock === label) {
    return translation;
  }

  return label;
};

export const getSearchPagePath = async (wixCodeApi: IWixAPI) => {
  const siteStructure = await wixCodeApi.site.getSiteStructure();
  const searchPath = siteStructure.pages.find(
    // @ts-expect-error
    (page) => page.applicationId === SEARCH_APP_APP_DEF_ID,
  )?.url;
  return searchPath;
};
