import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FederatedSuggestionDocument } from '../../lib/domain/federatedSuggestions';
import { RepeaterDataItem } from '../../lib/domain/repeaterDataItem';
import { getSizedImageUrl } from '../../utils/media';
import { IDocumentImage, isSearchEventDocument } from '@wix/client-search-sdk';
import { VerticalRepeaterItemClickHandler } from '../../types/types';
import { getDateFormatter } from './utils';

const populateItem = (
  $item: $w.$w,
  {
    title,
    image,
    description,
    dateDescription,
    relativeUrl,
  }: {
    title: string;
    image?: IDocumentImage;
    description?: string;
    dateDescription?: string;
    relativeUrl?: string;
  },
) => {
  if (image) {
    $item('#imageX1').src = getSizedImageUrl(image);
    $item('#verticalImageBox').restore();
  } else {
    $item('#verticalImageBox').delete();
  }

  $item('#verticalTitle').label = title;
  $item('#verticalTitle').link = relativeUrl;
  $item('#verticalDescription').text = description || dateDescription || '';
};

export const itemReady = (
  $item: $w.$w,
  itemData: RepeaterDataItem<FederatedSuggestionDocument>,
  flowAPI: PlatformControllerFlowAPI,
  viewMode: string,
  repeaterItemClickHandler: VerticalRepeaterItemClickHandler,
) => {
  const { t } = flowAPI.translations;
  const { image, relativeUrl } = itemData.itemData;
  const locale = flowAPI.controllerConfig.wixCodeApi.window.locale;
  const formatDate = getDateFormatter(locale);

  const title =
    viewMode === 'Editor'
      ? t(itemData.itemData.title)
      : itemData.itemData.title;
  const description =
    viewMode === 'Editor'
      ? t(itemData.itemData.description)
      : itemData.itemData.description;

  const commonData = {
    title,
    image,
    relativeUrl,
  };

  if (isSearchEventDocument(itemData.itemData)) {
    const eventItemData = itemData.itemData;
    const location =
      viewMode === 'Editor'
        ? t(eventItemData.location)
        : eventItemData.location;

    const dateDescription = `${
      eventItemData.startDate ? formatDate(eventItemData.startDate) : ''
    }\n${location || ''}`;

    populateItem($item, {
      ...commonData,
      dateDescription,
    });
  } else {
    populateItem($item, {
      ...commonData,
      description,
    });
  }

  $item('#repeaterItem').onClick(() => {
    repeaterItemClickHandler({
      relativeUrl,
      flowAPI,
      viewMode,
      title,
      bi: flowAPI.bi,
    });
  });
};
