import {
  QuerySuggestionsRepeaterItem,
  toRepeaterData,
} from './lib/querySuggestionsRepeaterItem';
import model from './model';
import { BI_EVENTS_CONSTS } from '../../utils/constants';
import { reportDocumentClickedBi } from '../../utils/biEventsLogger';
import { getSearchPagePath } from '@/utils/utils';
import debounce from 'lodash/debounce';
import { ILocation, VisitorLogger } from '@wix/yoshi-flow-editor';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/src/types/types';

const debouncedClickHandler = debounce(
  ({
    query,
    location,
    viewMode,
    searchPath,
    searchTerm,
    bi,
  }: {
    query: string;
    location: ILocation;
    viewMode: IWixWindowViewMode;
    searchTerm: string;
    searchPath: string | undefined;
    bi: VisitorLogger | null | undefined;
  }) => {
    reportDocumentClickedBi({
      resultClicked: query,
      pageUrl: `${location.baseUrl + searchPath}${query ? '?q=' + query : ''}`,
      clickOrigin: BI_EVENTS_CONSTS.clickOrigin.autoComplete,
      target: searchTerm,
      viewMode,
      bi,
    });
  },
  100,
);

export default model.createController(
  ({ $w, $bindAll, $widget, flowAPI, $props }) => {
    const {
      controllerConfig: {
        wixCodeApi: { location, window },
      },
      translations: { t },
      bi,
    } = flowAPI;
    let searchTerm = '';
    const viewMode = window.viewMode;
    return {
      pageReady: async () => {
        $widget.fireEvent('widgetLoaded', {});
        $widget.onPropsChanged((oldProps, newProps) => {
          searchTerm = newProps.searchTerm || '';
        });
        // todo: when we have api for trending searches, update translation to be dynamic
        $w('#suggestionsTitle').text = t(
          'siteSearch.viewer.suggestionBox.searchSuggestions.title',
        );
        const searchPath = await getSearchPagePath(
          flowAPI.controllerConfig.wixCodeApi,
        );
        // @ts-expect-error
        $w('#repeater').onItemReady(
          // @ts-expect-error
          ($item, { itemData }: QuerySuggestionsRepeaterItem) => {
            $item('#suggestionItemText').label = itemData.query;
            $item('#suggestionItemText').link = `${searchPath}${
              itemData.query ? '?q=' + itemData.query : ''
            }`;

            $item('#suggestionItemText').onClick(() => {
              debouncedClickHandler({
                query: itemData.query,
                location,
                viewMode,
                searchPath,
                searchTerm,
                bi,
              });
            });
          },
        );

        $bindAll({
          '#repeater': {
            // @ts-expect-error
            data: () => {
              const data = $props.values.reduce(
                toRepeaterData,
                [] as QuerySuggestionsRepeaterItem[],
              );
              // @ts-expect-error
              $w('#repeater').data = [];
              return data;
            },
          },
        });
      },
      exports: {},
    };
  },
);
