import model, { ElementsToDisplayValues } from './model';
import { createServices } from './services';
import { Store } from './lib/store';
import { maybe } from '@wix/site-search-common';
import { Controller } from './lib/controller';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { toggleComponentVisibility } from '../../utils/veloComps';
import {
  elementsToDisplayOptions,
  RESULT_SUGGESTIONS,
  SEARCH_SUGGESTIONS,
  SHOW_ALL_BUTTON,
} from './blocksPanels/Suggestion Elements/panel.controller';
import { FederatedAutocomplete } from '../../lib/domain/federatedAutocomplete';
import {
  loggerHelper,
  reportDocumentShownBi,
} from '../../utils/biEventsLogger';
import { BI_EVENTS_CONSTS, LOADER_TIMEOUT } from '../../utils/constants';
import { MainWidgetComponentId } from '../../types/types';

export const MAX_VERTICALS_SUGGESTIONS_TO_SHOW = 3;

const getSortedVerticalsSuggestionRepeaterData = (controller: Controller) => {
  const categoryList = controller.store.settings.data.categoryList;
  const verticalsSuggestionRepeaterData = controller.store.suggestions.data
    ?.filter(
      ({ documentType, documents }) =>
        documentType !== SearchDocumentType.Products && documents.length > 0,
    )
    .map((item) => ({
      ...item,
      documents: item.documents.slice(0, MAX_VERTICALS_SUGGESTIONS_TO_SHOW),
      _id: item.documentType.replace(/[^A-Za-z0-9-]/g, ''),
    }));

  verticalsSuggestionRepeaterData?.sort((a, b) => {
    const indexA = categoryList[a.documentType]?.index ?? Infinity;
    const indexB = categoryList[b.documentType]?.index ?? Infinity;

    return indexA - indexB;
  });
  return verticalsSuggestionRepeaterData;
};

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const {
    httpClient,
    errorMonitor,
    environment: { language, isViewer, isSSR, isEditor, isRTL },
    experiments,
    controllerConfig: { wixCodeApi },
    bi,
  } = flowAPI;
  const useDemoContent = isEditor;

  const viewMode = wixCodeApi.window.viewMode;

  const visibilityCache: Record<
    string,
    {
      isSearchSuggestionsIncluded: boolean;
      isVerticalsIncluded: boolean;
      isShowAllButtonIncluded: boolean;
    }
  > = {};

  const currentVisibilityState: Record<string, boolean> = {};

  const toggleAllComponentsVisibility = (
    controller: Controller,
    elementsToDisplay: string[],
  ) => {
    if (
      !controller.store.suggestions.ready ||
      !controller.store.autocomplete.ready
    ) {
      if (currentVisibilityState['#multiStateBox1']) {
        $w('#multiStateBox1').expand();
      }
      // @ts-expect-error
      $w('#multiStateBox1').accessibility.ariaBusy = true;
      return $w('#multiStateBox1').changeState('loader');
    }

    setTimeout(() => {
      if (currentVisibilityState['#multiStateBox1']) {
        $w('#multiStateBox1').changeState('results');
      } else {
        $w('#multiStateBox1').changeState('emptyState');
      }
      // @ts-expect-error
      $w('#multiStateBox1').accessibility.ariaBusy = false;
    }, LOADER_TIMEOUT);

    const cacheKey = JSON.stringify(elementsToDisplay);
    if (!visibilityCache[cacheKey]) {
      visibilityCache[cacheKey] = {
        isSearchSuggestionsIncluded: elementsToDisplay?.includes(
          SEARCH_SUGGESTIONS.value,
        ),
        isVerticalsIncluded: elementsToDisplay?.includes(
          RESULT_SUGGESTIONS.value,
        ),
        isShowAllButtonIncluded: elementsToDisplay?.includes(
          SHOW_ALL_BUTTON.value,
        ),
      };
    }

    const {
      isSearchSuggestionsIncluded,
      isVerticalsIncluded,
      isShowAllButtonIncluded,
    } = visibilityCache[cacheKey];

    const { productDocuments, suggestions, autocomplete } = controller.store;

    const noProducts = !productDocuments?.documents?.length;
    const noVerticalSuggestions =
      (suggestions.data?.reduce(
        (total, item) => total + item.documents.length,
        0,
      ) || 0) === 0;
    const noTopSuggestions = !autocomplete.data?.length;

    const updateVisibility = (
      componentId: MainWidgetComponentId,
      shouldShow: boolean,
    ) => {
      if (currentVisibilityState[componentId] !== shouldShow) {
        toggleComponentVisibility($w(componentId), shouldShow);
        currentVisibilityState[componentId] = shouldShow;
      }
    };

    const shouldShowResults =
      (isVerticalsIncluded && !(noProducts && noVerticalSuggestions)) ||
      (isSearchSuggestionsIncluded &&
        (!noTopSuggestions || viewMode === 'Editor'));
    if (currentVisibilityState['#multiStateBox1'] !== shouldShowResults) {
      currentVisibilityState['#multiStateBox1'] = shouldShowResults;
    }

    updateVisibility(
      '#productsAndVerticalsBox',
      isVerticalsIncluded && !(noProducts && noVerticalSuggestions),
    );
    updateVisibility(
      '#verticalsSuggestionRepeaterWidget',
      isVerticalsIncluded && !noVerticalSuggestions,
    );
    updateVisibility('#storesSlotWidget', isVerticalsIncluded && !noProducts);
    updateVisibility(
      '#searchSuggestionsBox',
      isSearchSuggestionsIncluded &&
        (!noTopSuggestions || viewMode === 'Editor'),
    );
    updateVisibility('#footerWidget', isShowAllButtonIncluded);
  };

  const controller = new Controller(
    new Store(),
    createServices({
      httpClient,
      errorMonitor,
      wixCodeApi,
      environment: { language, isViewer, isSSR },
      experiments,
    }),
    useDemoContent,
  );

  if (useDemoContent) {
    controller.services.clientSearchSDK.useDemoContent({
      shouldHaveSearchResults: true,
      useRawProductDocuments: true,
    });
  }
  const resultsBox = $w('#multiStateBox1');

  function handleResultsVisibilityBeforeInput({
    resultsBeforeInput,
    searchTerm,
    autocompleteData,
    elementsToDisplay,
  }: {
    resultsBeforeInput?: boolean | null;
    searchTerm?: string;
    autocompleteData?: FederatedAutocomplete[];
    elementsToDisplay?: ElementsToDisplayValues[];
  }) {
    if (viewMode !== 'Editor') {
      const isSearchSuggestionsIncludedInSettings = elementsToDisplay?.includes(
        SEARCH_SUGGESTIONS.value,
      );

      const isVerticalsIncludedInSettings = elementsToDisplay?.includes(
        RESULT_SUGGESTIONS.value,
      );
      if (
        resultsBeforeInput === true &&
        isSearchSuggestionsIncludedInSettings &&
        isVerticalsIncludedInSettings
      ) {
        resultsBox.expand();
      } else if (
        autocompleteData &&
        autocompleteData.length > 0 &&
        isSearchSuggestionsIncludedInSettings &&
        isVerticalsIncludedInSettings
      ) {
        resultsBox.expand();
      } else if (
        !searchTerm ||
        resultsBeforeInput === false ||
        !(
          isSearchSuggestionsIncludedInSettings && isVerticalsIncludedInSettings
        )
      ) {
        resultsBox.collapse();
      }
    }
  }
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      let { resultsBeforeInput, elementsToDisplay } = $widget.props;
      resultsBeforeInput = resultsBeforeInput !== false;

      if (
        isRTL &&
        flowAPI.controllerConfig.wixCodeApi.location.query.experiments ===
          'test.blocks.css'
      ) {
        console.log('test.blocks.css experiment is enabled');

        // @ts-expect-error
        $w('#box1').customClassList.add('ours_rtl');
        // @ts-expect-error
        $w('#results').customClassList.add('ours_rtl');
      }
      // if (isRTL) {
      // }

      const defaultOptions = elementsToDisplayOptions.map(
        (option) => option.value,
      );
      const { t } = flowAPI.translations;
      $w('#emptyStateText').text = t(
        'siteSearch.viewer.suggestionBox.emptyState.text',
        {
          searchTerm: controller.store.searchTerm || '',
        },
      );

      $widget.onPropsChanged((oldProps, newProps) => {
        resultsBeforeInput = newProps.resultsBeforeInput;
        elementsToDisplay = newProps.elementsToDisplay;

        handleResultsVisibilityBeforeInput({
          resultsBeforeInput,
          searchTerm: controller.store.searchTerm,
          elementsToDisplay,
        });
        toggleAllComponentsVisibility(
          controller,
          elementsToDisplay || defaultOptions,
        );
      });
      $bindAll({
        '#headerWidgetSideBySide1': {
          onSearchTermChange: (params) => {
            // @ts-expect-error
            const query = params.data.value;
            controller.changeSearchTerm(query);
            $w('#emptyStateText').text = t(
              'siteSearch.viewer.suggestionBox.emptyState.text',
              {
                searchTerm: controller.store.searchTerm,
              },
            );
          },
          autocomplete: () => controller.store.topAutocomplete,
        },
        '#storesSlotWidget': {
          products: () => {
            !controller.store.searchTerm && !resultsBeforeInput
              ? handleResultsVisibilityBeforeInput({
                  resultsBeforeInput,
                  elementsToDisplay,
                })
              : toggleAllComponentsVisibility(
                  controller,
                  elementsToDisplay || defaultOptions,
                );
            const { productDocuments } = controller.store;
            return (
              productDocuments && {
                totalCount: productDocuments.documents.length,
                list: maybe(productDocuments.documents),
              }
            );
          },
          ready: () => {
            const { ready, data } = controller.store.suggestions;

            if (data && ready && $w('#productsAndVerticalsBox').isVisible) {
              const resultsArray =
                loggerHelper.getDocumentResultsArrStringify(data);
              const documentIds = loggerHelper.getDocumentIdsStringify(data);

              reportDocumentShownBi({
                resultsArray,
                target: controller.store.searchTerm || '',
                origin: BI_EVENTS_CONSTS.clickOrigin.suggestions,
                documentIds,
                viewMode,
                bi,
              });
            }

            return ready;
          },
          title: () => controller.store.productDocuments?.title,
          searchTerm: () => controller.store.searchTerm,
          suggestionsResult: () => controller.store.suggestions.data,
        },
        '#verticalsSuggestionRepeaterWidget': {
          verticalsSuggestionRepeaterData: () => {
            const data =
              getSortedVerticalsSuggestionRepeaterData(controller) ?? [];
            toggleComponentVisibility(
              $w('#verticalsSuggestionRepeaterWidget'),
              data.length > 0,
            );
            return data.map((item) => ({
              ...item,
              searchTerm: controller.store.searchTerm,
            }));
          },
          searchTerm: () => controller.store.searchTerm,
        },
        '#searchSuggestions1': {
          values: () => {
            const { data, ready } = controller.store.autocomplete;
            if (
              data &&
              data.length > 0 &&
              ready &&
              $w('#searchSuggestions1').isVisible
            ) {
              const resultsArray =
                loggerHelper.getResultsArrayAutoCompleteStringify(data);
              const documentIds =
                loggerHelper.getDocumentIdsAutoCompleteStringify(data);

              reportDocumentShownBi({
                resultsArray,
                target: controller.store.searchTerm || '',
                origin: BI_EVENTS_CONSTS.clickOrigin.autoComplete,
                documentIds,
                viewMode,
                bi,
              });
            }

            return data || [];
          },
          searchTerm: () => {
            return controller.store.searchTerm;
          },
        },
        '#footerWidget': {
          searchTerm: () => {
            return controller.store.searchTerm || '';
          },
        },
      });
    },
    exports: {},
  };
});
